<template>
  <div
    ref="dropdownContainer"
    class="dropdown position-relative mb-8"
    role="menu"
    aria-expanded="false"
  >
    <!-- dropdown value -> only displayed on mobile -->
    <div
      id="home-page-games-slider"
      ref="dropdownToggle"
      class="d-block d-lg-none cursor-pointer"
      data-bs-toggle="dropdown"
      data-bs-offset="0,20"
      data-bs-reference="parent"
      role="menuitem"
    >
      <home-page-games-slider-header
        :header-model="currentModel"
        :is-static="true"
        :is-active="true"
        :is-dropdown-shown="dropdownMenuShown"
        is-dropdown
      />
    </div>
    <!-- dropdown options -> will become horizontal and all values become visible when in desktop -->
    <div
      class="home-page__games-tabswitch dropdown-menu dropdown-menu-right d-lg-flex justify-content-lg-center p-4 p-lg-0"
      aria-labelledby="home-page-games-slider"
      role="menuitem"
    >
      <home-page-games-slider-header
        v-for="model in headerModels"
        :key="model.group"
        :header-model="model"
        :is-active="model.group === currentGamesSliderGroup"
        @toggle-games-slider="toggleGamesSlider(model.group)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import useDropdown from '~/composables/bootstrap/useDropdown';
import { GamesSliderGroup, type GamesSliderHeaderModel } from './types';

export default defineComponent({
  emits: ['games-slider-switched'],
  setup(_, { emit }) {
    const { t } = useI18n();

    const headerModels: Array<GamesSliderHeaderModel> = [
      {
        icon: {
          alt: 'Mr. Maintenance',
          component: defineAsyncComponent(
            () => import('~/assets/icons/mr-maintenance.svg?component'),
          ),
        },
        title: t('landingpage/index/top_games') as string,
        group: GamesSliderGroup.Popular,
      },
      {
        icon: {
          alt: 'Gamepad',
          component: defineAsyncComponent(
            () => import('~/assets/icons/gamepad.svg?component'),
          ),
        },
        title: t('homePage/newest_games') as string,
        group: GamesSliderGroup.New,
      },
    ];

    const currentGamesSliderGroup = ref<GamesSliderGroup>(
      GamesSliderGroup.Popular,
    );

    const currentModel = computed(() =>
      headerModels.find(
        (model) => model.group === currentGamesSliderGroup.value,
      ),
    );

    const toggleGamesSlider = (group: string) => {
      currentGamesSliderGroup.value = GamesSliderGroup[group];
      emit('games-slider-switched', group);
    };
    const dropdownToggle = ref<HTMLElement>(null);
    const dropdownContainer = ref<HTMLElement>(null);
    const { dropdownMenuShown } = useDropdown(
      dropdownToggle,
      dropdownContainer,
    );

    return {
      dropdownToggle,
      dropdownContainer,
      currentModel,
      currentGamesSliderGroup,
      headerModels,
      dropdownMenuShown,
      toggleGamesSlider,
    };
  },
});
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';
@import 'gportal-theme/scss/colors.scss';

.home-page__games {
  &-tabswitch {
    background: $gp-midnight-2;
    width: 100%;

    // this is to override inline and bootstrap styling
    @include media-breakpoint-up(lg) {
      transform: none !important;
      position: static !important;
      inset: none !important;
      margin: unset !important;
      background: transparent;
      box-shadow: none;
      gap: 2rem;
      border: none;
      width: auto;
    }
  }
}
</style>
