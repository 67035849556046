<template>
  <div class="partners-gallery pt-8 pb-8">
    <div class="mb-8 container">
      <h1 class="container text-center mb-3">{{ component.headline }}</h1>
      <p class="container text-center mb-8 fs-4 partners-gallery__promo-text">
        {{ component.promoText }}
      </p>
      <div
        id="home-page-partners-gallery"
        ref="element"
        class="carousel-wrapper carousel slide"
      >
        <div class="carousel-inner d-md-flex h-100 gap-4">
          <div
            v-for="(image, index) in component.images"
            :key="image.id"
            class="carousel-item h-100 text-center mt-5 pt-1 partners-gallery__carousel-item"
            :class="{ active: index === 0, 'd-md-block': true }"
          >
            <strapi-image
              :image="image.image"
              :alt-fallback="image.title"
              class="partners-gallery__carousel-item-image"
            />
          </div>
        </div>
        <template v-if="component.images.length > 1">
          <button
            aria-label="Previous"
            class="carousel-control carousel-control-prev top-50 start-0 translate-middle-y d-md-none"
            data-bs-slide="prev"
            data-bs-target="#home-page-partners-gallery"
            type="button"
          >
            <fa-icon
              class="text-rhino lh-lg fs-2 shadow"
              :icon="faChevronLeft"
              aria-hidden="true"
            />
          </button>

          <button
            aria-label="Next"
            class="carousel-control carousel-control-next top-50 end-0 translate-middle-y d-md-none"
            data-bs-slide="next"
            data-bs-target="#home-page-partners-gallery"
            type="button"
          >
            <fa-icon
              class="text-rhino lh-lg fs-2 shadow"
              :icon="faChevronRight"
              aria-hidden="true"
            />
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { StrapiHomePagePartnersGalleryComponent } from '~/apollo/types/types';
import useBootstrapCarousel from '~/composables/bootstrap/useCarousel';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/pro-solid-svg-icons';

export default defineComponent({
  name: 'StrapiHomePagePartnersGallery',
  props: {
    component: {
      type: Object as PropType<StrapiHomePagePartnersGalleryComponent>,
      required: true,
    },
  },
  setup() {
    const { smaller } = useAppBreakpoints();
    const isSmallerMd = smaller('md');
    const element = ref<HTMLElement>();
    const currentSlideIndex = ref<number>(0);
    const { carouselInstance, instanceAvailable } = useBootstrapCarousel(
      element,
      {
        interval: 5000,
      },
      (event) => {
        currentSlideIndex.value = event.to;
      },
    );

    const toggleSlider = () => {
      if (carouselInstance.value == null) return;

      if (isSmallerMd.value) {
        carouselInstance.value.cycle();
      } else {
        carouselInstance.value.pause();
      }
    };

    watch(
      () => instanceAvailable.value,
      () => {
        if (!instanceAvailable.value) return;

        toggleSlider();
      },
    );

    watch(() => isSmallerMd.value, toggleSlider);

    return {
      faChevronRight,
      faChevronLeft,

      element,
      currentSlideIndex,
    };
  },
});
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';

.partners-gallery {
  &__promo-text {
    font-weight: normal;
  }

  &__carousel-item {
    @include media-breakpoint-up(md) {
      width: 20%;
      float: none;
      margin-right: 0;
    }
  }

  &__carousel-item-image {
    width: 100%;
    max-width: 200px;

    @include media-breakpoint-up(md) {
      max-width: initial;
    }
  }
}

.carousel-wrapper {
  height: 11rem;

  @include media-breakpoint-up(sm) {
    overflow: hidden;
  }
}
</style>
