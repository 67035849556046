<template>
  <component
    :is="icon"
    class="gp-svg-inline-icon"
    :class="{ 'gp-svg-inline-icon--hoverable': !disableHover }"
    v-bind="attrs"
    :style="{
      '--gp-icon-fill': fill,
      '--gp-icon-hover-fill': hoverFill,
    }"
  />
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    /**
     * The icon as a vue component
     */
    icon: object;
    /**
     * If true, the icon will be highlighted with the hightlight fill
     */
    isHighlighted?: boolean;
    /**
     * default fill. Can be a color or a reference to a gradient def
     */
    defaultFill?: string;
    /**
     * fill to use when the icon is highlighted. Can be a color or a reference to a gradient def
     */
    hightlightFill?: string;
    /**
     * fill to use when the icon is hovered. Can be a color or a reference to a gradient def
     * If not provided, the hightlight fill will be used
     */
    hoverFill?: string;
    /**
     * If true, the hover effect will be disabled
     */
    disableHover?: boolean;
  }>(),
  {
    isHighlighted: false,
    defaultFill: 'currentColor',
    hightlightFill: undefined,
    hoverFill: undefined,
    disableHover: false,
  },
);

const id = useState('svg-fancy-gradient');
const fancyGradient = computed(() => `url(#${id.value})`);
/**
 * Returns the fill value for the icon. If the icon is highlighted, it returns the highlight fill, otherwise the default fill
 * The default hightlight fill is the fancy gradient
 */
const fill = computed(() => {
  if (props.isHighlighted) {
    return props.hightlightFill || fancyGradient.value;
  }

  return props.defaultFill;
});

const hoverFill = computed(() => props.hoverFill || fancyGradient.value);
const attrs = useAttrs();
</script>
<style lang="scss" scoped>
.gp-svg-inline-icon {
  &--hoverable {
    &:hover {
      --gp-icon-fill: var(--gp-icon-hover-fill) !important;
    }
  }
}
</style>
