<template>
  <div
    class="home-page__games-header d-flex justify-content-center text-uppercase"
    :class="{
      'home-page__games-header--active d-none d-lg-flex': !isStatic && isActive,
      'cursor-pointer': !isStatic && !isActive,
      'home-page__games-header--active': isStatic && isActive,
    }"
    @click="toggleGamesSlider"
  >
    <common-svg-icon
      class="home-page__games-header-icon me-2"
      :icon="headerModel.icon.component"
      :alt="headerModel.icon.alt"
      :width="24"
      :height="24"
      :is-highlighted="isActive"
    />
    <h2 class="underscore-gradient m-0 mb-lg-4 text-center fs-4">
      {{ headerModel.title }}
    </h2>
    <fa-icon
      v-if="isActive"
      :icon="faChevronDown"
      class="ms-2 fs-3 text-rhino d-lg-none"
      :style="{
        transform: isDropdownShown ? 'rotate(180deg)' : 'rotate(0deg)',
      }"
    />
  </div>
</template>

<script lang="ts">
import type { GamesSliderHeaderModel } from './types';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

export default defineComponent({
  props: {
    isStatic: {
      type: Boolean,
      required: false,
    },
    isActive: {
      type: Boolean,
      required: false,
    },
    headerModel: {
      type: Object as PropType<GamesSliderHeaderModel>,
      required: true,
    },
    isDropdownShown: {
      type: Boolean,
      default: false,
    },
    isDropdown: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle-games-slider'],
  setup(props, { emit }) {
    const toggleGamesSlider = () => {
      emit('toggle-games-slider', props.headerModel.group);
    };

    return {
      faChevronDown,

      toggleGamesSlider,
    };
  },
});
</script>

<style scoped lang="scss">
@import 'gportal-theme/scss/colors.scss';

.home-page__games {
  &-header {
    &--active {
      .underscore-gradient {
        &:after {
          opacity: 1 !important;
        }
      }
    }

    &:hover {
      .underscore-gradient {
        &:after {
          opacity: 1 !important;
        }
      }

      // change color, if the whole header is hovered
      .home-page__games-header-icon {
        --gp-icon-fill: var(--gp-icon-hover-fill) !important;
      }
    }

    &-icon {
      color: $gp-rhino;
    }

    .underscore-gradient {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 2px;
        opacity: 0;
        transition: opacity 0.5s ease;
        background: $gradient-rainbow;
      }
    }
  }
}
</style>
