<template>
  <div :id="id" ref="element" class="blockbuster carousel slide">
    <div class="carousel-inner h-100">
      <div
        v-for="(item, index) in items"
        :key="item.id"
        class="carousel-item h-100"
        :class="{ active: index === 0 }"
      >
        <strapi-image
          class="blockbuster__item-background position-absolute top-0 h-100"
          :image="item.backgroundImage"
          :lazy="index !== 0"
          fluid
          :alt-fallback="item.title"
        />
        <div
          class="blockbuster__item-content container position-relative col-12 col-md-9 mx-0 mx-lg-auto py-4 py-md-0 px-4 px-md-6 px-lg-3 d-flex flex-column justify-content-start justify-content-md-center text-center text-md-start"
        >
          <h2>{{ item.headline }}</h2>
          <h1>{{ item.title }}</h1>
          <p class="col-12 col-xl-9 fs-5 m-0">{{ item.text }}</p>
          <div class="col-8 col-md-12 w-lg-auto mt-4 mt-md-8 mx-auto mx-md-0">
            <template v-for="link in item.buttons">
              <nuxt-link
                v-if="isNuxtRoute(link.link)"
                :key="link.link"
                :to="link.link"
                :class="[
                  `btn-${link.type}`,
                  'btn btn-lg d-block d-md-inline me-0 mb-4 me-md-4 mb-md-0',
                ]"
                :target="link.newTab ? '_blank' : '_self'"
              >
                {{ link.text }}
              </nuxt-link>
              <a
                v-else
                :key="`static-${link.link}`"
                :href="link.link"
                :class="[
                  `btn-${link.type}`,
                  'btn btn-lg d-block d-md-inline me-0 mb-4 me-md-4 mb-md-0',
                ]"
                :target="link.newTab ? '_blank' : '_self'"
              >
                {{ link.text }}
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>

    <home-page-blockbuster-indicator
      v-if="items.length > 1"
      class="w-100 position-relative"
      :parent-id="id"
      :current-slide-index="currentSlideIndex"
      :count="items.length"
      @slide-to-index="slideToIndex"
    />

    <template v-if="items.length > 1">
      <button
        aria-label="Previous"
        class="carousel-control carousel-control-prev top-50 start-0 translate-middle-y ms-4"
        data-bs-slide="prev"
        :data-bs-target="`#${id}`"
        type="button"
      >
        <fa-icon
          class="text-rhino lh-lg fs-2 shadow"
          :icon="faChevronLeft"
          aria-hidden="true"
        />
      </button>

      <button
        aria-label="Next"
        class="carousel-control carousel-control-next top-50 end-0 translate-middle-y me-4"
        data-bs-slide="next"
        :data-bs-target="`#${id}`"
        type="button"
      >
        <fa-icon
          class="text-rhino lh-lg fs-2 shadow"
          :icon="faChevronRight"
          aria-hidden="true"
        />
      </button>
    </template>
  </div>
</template>

<script lang="ts">
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import useBootstrapCarousel from '~/composables/bootstrap/useCarousel';
import type { StrapiBlockbusterItemComponent } from '~/apollo/types/types';

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<Array<StrapiBlockbusterItemComponent>>,
      required: true,
    },
  },
  setup() {
    const element = ref<HTMLElement>();
    const currentSlideIndex = ref<number>(0);
    const { carouselInstance, instanceAvailable } = useBootstrapCarousel(
      element,
      {
        interval: 5000,
        cycleOnStart: true,
      },
      (event) => {
        currentSlideIndex.value = event.to;
      },
    );

    const { isOutside } = useMouseInElement(element);
    watch(
      () => isOutside.value,
      (newValue) => {
        if (!instanceAvailable.value) return;

        if (newValue) {
          carouselInstance.value.cycle();
        } else {
          carouselInstance.value.pause();
        }
      },
    );

    const slideToIndex = (index: number) => {
      currentSlideIndex.value = index;
      carouselInstance.value.to(index);
    };

    const router = useRouter();
    const config = useRuntimeConfig();
    const isNuxtRoute = (url: string) => {
      // cleanup url from domain
      if (url.startsWith(config.public.websiteUrl)) {
        const parsedUrl = url.replace(config.public.websiteUrl, '');
        return router.resolve(parsedUrl).matched.length > 0;
      }

      return router.resolve(url).matched.length > 0;
    };

    return {
      faChevronLeft,
      faChevronRight,

      element,
      currentSlideIndex,
      isNuxtRoute,
      slideToIndex,
    };
  },
});
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/mixins/breakpoints';
@import 'gportal-theme/scss/breakpoints.scss';

.blockbuster {
  height: 32rem;

  @include media-breakpoint-up(md) {
    height: 25rem;
  }

  @include media-breakpoint-up(lg) {
    height: 38rem;
  }

  &__item {
    &-background {
      filter: brightness(60%);
      -webkit-mask-image: linear-gradient(180deg, #fff 40%, transparent 90%);
      mask-image: linear-gradient(180deg, #fff 40%, transparent 90%);
      object-fit: cover;
      width: 100%;
    }

    &-content {
      height: 100%;

      @include media-breakpoint-up(md) {
        height: calc(100% - 100px);
      }
    }
  }

  &__indicator {
    margin-top: -6rem;

    @include media-breakpoint-up(md) {
      margin-top: -4rem;
    }

    @include media-breakpoint-up(lg) {
      margin-top: -8rem;
    }
  }

  .carousel {
    &-control {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
        opacity: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      .carousel-control {
        opacity: 1;
      }
    }
  }
}
</style>
