<template>
  <ul class="blockbuster__indicator d-flex px-4">
    <home-page-blockbuster-indicator-item
      v-for="(icon, index) in indicatorIcons"
      :key="index"
      class="d-block"
      :data-bs-target="parentId"
      :data-bs-slide-to="index"
      :icon="icon"
      :is-active="index === currentSlideIndex"
      :index="index"
      @click="slideToIndex(index)"
    />
  </ul>
</template>

<script lang="ts" setup>
import type { FunctionalComponent } from 'vue';

const props = withDefaults(
  defineProps<{
    parentId: string;
    currentSlideIndex: number;
    count: number;
  }>(),
  {
    currentSlideIndex: 0,
    count: 0,
  },
);

const emit = defineEmits<{
  (e: 'slide-to-index', index: number): void;
}>();

const slideToIndex = (index: number) => {
  emit('slide-to-index', index);
};

const availableIcons = [
  defineAsyncComponent(
    () => import('~/assets/icons/mr-maintenance.svg?component'),
  ),
  defineAsyncComponent(() => import('~/assets/icons/gamepad.svg?component')),
  defineAsyncComponent(() => import('~/assets/icons/d-pad.svg?component')),
  defineAsyncComponent(() => import('~/assets/icons/circle.svg?component')),
];

const indicatorIcons = computed(() => {
  const icons: FunctionalComponent[] = [];
  for (let i = 0; i < props.count; ++i) {
    icons.push(availableIcons[i % availableIcons.length]);
  }

  return icons;
});
</script>
<style scoped lang="scss">
@import 'node_modules/bootstrap/scss/mixins/breakpoints';
@import 'node_modules/gportal-theme/scss/breakpoints.scss';

.blockbuster__indicator {
  justify-content: space-between;

  @include media-breakpoint-up(sm) {
    justify-content: center;
    gap: 2rem;
  }
}
</style>
