<template>
  <strapi-content-components class="overflow-hidden">
    <template v-for="c in contents">
      <lazy-strapi-product-benefits
        v-if="c.component === 'product.benefits'"
        :key="`${c.component}__${c.id}`"
        class="mb-16"
        :component="c"
        :large-header="true"
      />
      <lazy-strapi-media-gallery
        v-if="c.component === 'media-gallery'"
        :key="`${c.component}__${c.id}`"
        class="mb-16"
        :component="c"
        :show-title="false"
      />
      <lazy-strapi-slide-box
        v-if="c.component === 'home-page.slide-box'"
        :key="`${c.component}__${c.id}`"
        class="mb-16"
        :component="c"
      />
      <strapi-home-page-custom-components
        v-if="c.component === 'product.custom-component'"
        :key="`${c.component}__${c.id}`"
        :component="c"
        :order-url="''"
      />
      <lazy-strapi-banner
        v-if="c.component === 'generic.banner'"
        :key="`${c.component}__${c.id}`"
        :component="c"
        full-width-on-mobile
        class="mt-28"
      />
      <lazy-strapi-product-content
        v-if="c.component === 'product.content'"
        :key="`${c.component}__${c.id}`"
        :component="c"
        :content-class="'text-center fs-5 mb-8'"
      />
    </template>
  </strapi-content-components>
</template>

<script lang="ts">
import type { StrapiContent } from '~/apollo/types/types';

export default defineComponent({
  props: {
    contents: {
      required: true,
      type: Array as PropType<Array<StrapiContent>>,
    },
  },
});
</script>
