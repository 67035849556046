<template>
  <div>
    <div class="mb-8 row">
      <h2
        class="col offset-0 offset-lg-3 mb-8 mb-lg-0 text-uppercase text-center fs-1"
      >
        {{
          currentNewsType.key === 'all'
            ? t('news/title')
            : currentNewsType.label
        }}
      </h2>
      <div
        class="col-12 col-lg-3 d-flex justify-content-end align-items-center"
      >
        <form-dropdown
          class="col-12 col-sm-4 col-md-3 col-lg-12"
          :label="t('landingpage/index/games/sort_by')"
          :items="newsTypeOptions"
          :item-class="'text-capitalize'"
          :value="currentNewsType"
          @change="selectNewsType"
        />
      </div>
    </div>
    <news-grid
      v-if="news.length > 0"
      class="mb-8 gx-8 gy-8"
      :articles="news"
      top-article-has-full-width-mobile
    />
    <div class="d-flex justify-content-center">
      <nuxt-link class="btn btn-primary btn-sm d-block" :to="allNewsLink">
        {{
          t('homePage/to_all', {
            context: t('general/non_special_texts/news_plural'),
          })
        }}
      </nuxt-link>
    </div>
  </div>
</template>

<script lang="ts">
import {
  GetNewsArticlesDocument,
  type GetNewsArticlesQuery,
  type GetNewsArticlesQueryVariables,
  type StrapiArticle,
} from '~/apollo/types/types';
import { useNewsTypes } from '~/components/news/features/useNewsTypes';
import type SelectItem from '~/components/form/dropdown/types';

export default defineComponent({
  async setup() {
    const { locale, t } = useI18n();
    const allNewsLink = computed<string>(() => `/${locale.value}/news`);

    const { newsTypeOptions, currentNewsType, currentNewsTypeForGql } =
      useNewsTypes();

    const { $sentry } = useNuxtApp();
    const { refresh, data: news } = await useAsyncData(
      'gp-home-page-news',
      async () => {
        try {
          const { error, data } = await useLegacyApollo<
            GetNewsArticlesQuery,
            GetNewsArticlesQueryVariables
          >(GetNewsArticlesDocument, {
            locale: locale.value,
            type: currentNewsTypeForGql.value,
            limit: 4,
            offset: 0,
          });

          if (error == null && data != null) {
            return data.articles as Array<StrapiArticle>;
          }
          $sentry.captureException(error);
        } catch (error) {
          $sentry.captureException(error);
        }

        return [];
      },
    );

    const selectNewsType = (val: SelectItem) => {
      currentNewsType.value = val;
      refresh();
    };

    return {
      t,
      news,
      allNewsLink,
      currentNewsType,
      newsTypeOptions,
      selectNewsType,
    };
  },
});
</script>
