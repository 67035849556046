<template>
  <div>
    <home-page-blockbuster-module
      :id="'home-page-blockbuster'"
      :items="data.blockbuster.items"
    />
  </div>
</template>

<script lang="ts">
import type { StrapiHomePage } from '~/apollo/types/types';

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<StrapiHomePage>,
      required: true,
    },
  },
});
</script>
