<template>
  <li :class="{ 'cursor-pointer': !isActive }" @click="emit('click')">
    <common-svg-icon
      class="blockbuster__indicator-icon text-rhino"
      :class="{ 'opacity-100': isActive }"
      :icon="icon"
      alt="indicator"
      :width="20"
      :height="20"
      :is-highlighted="isActive"
    />
  </li>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    index: {
      type: Number,
      required: true,
    },
    icon: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup(_, { emit }) {
    return { emit };
  },
});
</script>

<style scoped lang="scss">
@import 'node_modules/gportal-theme/scss/colors';

.blockbuster {
  &__indicator {
    &-icon {
      transition: opacity 0.5s ease;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
