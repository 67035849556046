<template>
  <main class="text-white">
    <template v-if="homePageData">
      <home-page-blockbuster :data="homePageData" />

      <lazy-strapi-countdown
        v-if="homePageData.countdown != null && homePageData.countdown.endDate"
        :component="homePageData.countdown"
      />

      <strapi-top-games-slider :data="homePageData" />

      <strapi-home-page-partners-gallery
        v-if="
          homePageData.partnersGallery != null &&
          homePageData.partnersGallery.images.length > 0
        "
        class="mb-7"
        :component="homePageData.partnersGallery"
      />

      <home-page-news class="mb-16 container overflow-x-hidden" />

      <strapi-home-page-components
        v-if="homePageData.content != null"
        :contents="homePageData.content"
      />

      <common-svg-gradient-definition />
    </template>
  </main>
</template>

<script lang="ts" setup>
import {
  GetHomePageDocument,
  type GetHomePageQuery,
  type GetHomePageQueryVariables,
  type StrapiHomePage,
} from '~/apollo/types/types';

const { locale } = useI18n();
const { $sentry } = useNuxtApp();
const { data } = await useAsyncData('gp-home-page', async () => {
  try {
    const { error, data: response } = await useLegacyApollo<
      GetHomePageQuery,
      GetHomePageQueryVariables
    >(GetHomePageDocument, {
      locale: locale.value,
    });

    if (error == null && response !== null && response.homePage != null) {
      return response.homePage as unknown as StrapiHomePage;
    }

    if (error != null) {
      $sentry.captureException(error);
    }
  } catch (error) {
    $sentry.captureException(error);
  }

  return null;
});

const { redirectToDefaultLocale } = useRedirectToLocale();
if (data.value == null && locale.value !== 'en') {
  await redirectToDefaultLocale();
}
const homePageData = computed(() => data.value);
const localizedSlugs = computed(() => {
  if (homePageData.value == null) {
    return null;
  }
  return homePageData.value.localizedSlugs;
});

useI18nLocalizedSlugs(localizedSlugs);

useHead({
  title: () => homePageData.value?.metaTitle,
});

const config = useRuntimeConfig();
const route = useRoute();
useSeoMeta({
  title: () => homePageData.value?.metaTitle,
  description: () => homePageData.value?.metaDescription,
  ogTitle: () => homePageData.value?.metaTitle,
  ogDescription: () => homePageData.value?.metaDescription,
  ogImage: `https://ccdn.g-portal.com/GP_BG_Blog_News_5f991dc0d5.webp`,
  ogUrl: () => `${config.public.websiteUrl}${route.fullPath}`,
  ogImageAlt: () => homePageData.value?.metaTitle,
  twitterTitle: () => homePageData.value?.metaTitle,
  twitterDescription: () => homePageData.value?.metaDescription,
  twitterImage: `https://ccdn.g-portal.com/GP_BG_Blog_News_5f991dc0d5.webp`,
  twitterImageAlt: () => homePageData.value?.metaTitle,
});
</script>

<style scoped lang="scss"></style>
