<template>
  <div>
    <div class="mb-8 container">
      <h1 class="container text-center mb-8">
        {{ data.headline }}
      </h1>

      <div class="d-flex justify-content-center">
        <home-page-games-slider-tabswitch
          class="col-12 col-sm-9 col-lg-12"
          :current-group="currentGamesSliderGroup"
          @games-slider-switched="toggleGamesSlider"
        />
      </div>

      <div class="d-flex justify-content-center">
        <nuxt-link class="btn btn-primary btn-sm d-block" :to="indexUrl">
          {{
            t('homePage/to_all', {
              context: t('general/non_special_texts/server_plural'),
            })
          }}
        </nuxt-link>
      </div>
    </div>

    <gameserver-index-top-games
      :products="currentSliderProducts"
      :display-header="false"
      class="mb-8"
    >
      <!-- do not render the header -->
      <template #header></template>
    </gameserver-index-top-games>
  </div>
</template>

<script lang="ts" setup>
import {
  GetHomePageProductsDocument,
  type GetHomePageProductsQuery,
  type GetHomePageProductsQueryVariables,
  type StrapiHomePage,
  type StrapiProduct,
} from '~/apollo/types/types';
import { GamesSliderGroup } from '~/components/home-page/games-slider/types';

defineProps<{
  data: StrapiHomePage;
}>();

const router = useRouter();
const { t, locale } = useI18n();

const indexUrl = computed(() => {
  const currentLocalisedSlug = router
    .getRoutes()
    .find(
      (route) => route.name === `landingpage-gameserver-slug___${locale.value}`,
    );

  if (currentLocalisedSlug != null) {
    return currentLocalisedSlug.path.replace('/:slug()', '/index');
  }

  return '/en/gameserver/index';
});
const currentGamesSliderGroup = ref<GamesSliderGroup>(GamesSliderGroup.Popular);
const { $sentry } = useNuxtApp();
const currencyStore = useCurrencyStore();
const { refresh, data: gamesSliderData } = await useAsyncData(
  'gp-top-games-slider',
  async () => {
    try {
      const { data: response } = await useLegacyApollo<
        GetHomePageProductsQuery,
        GetHomePageProductsQueryVariables
      >(GetHomePageProductsDocument, {
        locale: locale.value,
        currency: currencyStore.currency,
        group: currentGamesSliderGroup.value,
      });

      const products = response.products as Array<StrapiProduct>;
      products.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

      return products.map((p) => ({
        ...p,
        // we have to add the group to the id here or else there will be some strange scroll behaviour when switching between groups
        id: `${currentGamesSliderGroup.value}__${p.id}`,
      }));
    } catch (error) {
      $sentry.captureException(error);
    }

    return [];
  },
);

const currentSliderProducts = computed(() => {
  return gamesSliderData.value;
});

const toggleGamesSlider = (group: string) => {
  currentGamesSliderGroup.value = GamesSliderGroup[group];
  refresh();
};

useUserCurrency(refresh);
</script>
