import type { FunctionalComponent } from 'vue';

export enum GamesSliderGroup {
  Popular = 'Popular',
  New = 'New',
}

export type GamesSliderHeaderModel = {
  icon: {
    component: FunctionalComponent;
    alt: string;
  };
  title: string;
  group: GamesSliderGroup;
};

export default {};
